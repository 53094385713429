import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ClassInfo } from "../../shared-types";
import { fetchSchedule } from "../../utils/sanityUtils";

const ScheduleTable: React.FC = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [scheduleData, setScheduleData] = useState<ClassInfo[]>([]);
  const weekdays = t("weekdays", {
    returnObjects: true,
  }) as { [key: string]: string };

  // Fetch schedule when language changes
  useEffect(() => {
    fetchSchedule(i18n.language).then((dataFromSanity: ClassInfo[]) => {
      setScheduleData(dataFromSanity);
    });
  }, [i18n.language]);

  // Create a schedule map for easier rendering
  const scheduleMap: { [key: string]: ClassInfo[] } = {};
  scheduleData.forEach((classInfo) => {
    if (!scheduleMap[classInfo.day]) {
      scheduleMap[classInfo.day] = [];
    }
    scheduleMap[classInfo.day].push(classInfo);
  });

  return (
    <Box
      sx={{
        margin: "0 auto",
        width: { xs: "100%", sm: 440 },
      }}
    >
      {/* Create the table structure using Stack */}
      <Stack spacing={{ xs: 4, sm: 5 }}>
        {Object.keys(scheduleMap).map((day) => (
          <Box
            key={weekdays[day]}
            sx={{
              borderBottom: `1px dashed ${theme.palette.primary.main}`,
              paddingBottom: 1,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {weekdays[day]}
            </Typography>
            {scheduleMap[day].map((classInfo) => (
              <Box
                key={
                  classInfo.className + weekdays[classInfo.day] + classInfo.time
                }
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  paddingTop: 0.5,
                  paddingBottom: 0.5,
                }}
              >
                <Typography
                  component={"time"}
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                    width: { xs: "80px", sm: "120px" },
                    minWidth: "80px",
                  }}
                >
                  {`${classInfo.time}`}
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.dark,
                    fontWeight: "bold",
                    marginRight: "auto",
                  }}
                >
                  {`${classInfo.className}`}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  paddingLeft={2}
                  sx={{ flexShrink: 0 }}
                >
                  <Typography variant="body2">
                    {`${t("intensity")} ${classInfo.intensity}`}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        ))}
        <FormHelperText
          sx={{
            marginBottom: 2,
            color: theme.palette.primary.dark,
          }}
        >
          {t("intensityExplanation")}
        </FormHelperText>
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 8,
          marginBottom: 8,
        }}
      >
        <Button
          component={"a"}
          href="https://vello.fi/wellinyoga/service"
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`${t("ariaLabels.bookInVello")}`}
          variant="contained"
        >
          {t("bookInVello")}
          <OpenInNewIcon sx={{ fontSize: "12px", paddingLeft: 1 }} />
        </Button>
      </Box>
    </Box>
  );
};

export default ScheduleTable;
