import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { i18n } from "i18next";
import { useHistoryState } from "../ScrollTracker";
import { getAlternateLang } from "../../utils/utils";

const LanguageToggleButton: React.FC = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const visibleUrl = useHistoryState(); // Get the visible URL from the custom hook

  // Map paths for translation
  const getTranslatedPath = (currentPath: string, i18n: i18n): string => {
    // Get path mappings for both languages
    const enPaths = i18n.getResource("en", "translation", "paths") as Record<
      string,
      string
    >;
    const fiPaths = i18n.getResource("fi", "translation", "paths") as Record<
      string,
      string
    >;

    // Create a bidirectional mapping for both languages
    const pathMappings = new Map<string, string>();

    // Add English paths
    Object.entries(enPaths).forEach(([key, path]) => {
      pathMappings.set(path, key); // path -> key mapping
      if (path === "/home") pathMappings.set("/", key); // Special case for root path
    });

    // Add Finnish paths
    Object.entries(fiPaths).forEach(([key, path]) => {
      pathMappings.set(path, key); // path -> key mapping
    });

    // Get the path key from the current path
    const pathKey = pathMappings.get(currentPath);

    if (!pathKey) return currentPath;

    // Get the translated path using the key
    const targetLanguage = i18n.language;
    const translatedPath = i18n.getResource(
      targetLanguage,
      "translation",
      `paths.${pathKey}`,
    );

    return translatedPath || currentPath;
  };

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);

    // Update local storage with the selected language
    localStorage.setItem("wellinyogaLanguage", language);

    // Get the translated path based on the current visible URL or location.pathname
    const newPath = getTranslatedPath(visibleUrl || location.pathname, i18n);

    // Navigate to the translated path
    navigate(newPath);
  };

  useEffect(() => {
    // Check local storage for a saved language preference
    const savedLanguage = localStorage.getItem("wellinyogaLanguage");

    // If no saved language and the location.pathname is English specific
    if (!savedLanguage && location.pathname !== "") {
      // If there's the current path in English translations then change language to English
      const pathsEN = i18n.getResource("en", "translation", "paths") as Record<
        string,
        string
      >;

      Object.values(pathsEN).some((path) => path === location.pathname) &&
        i18n.changeLanguage("en");
    } else if (savedLanguage) {
      // If saved language exists, use that
      i18n.changeLanguage(savedLanguage);
      // Navigate to the correct URL to reflect the new language in use
      const newPath = getTranslatedPath(location.pathname, i18n);
      navigate(newPath);
    }
  }, [i18n, location.pathname, navigate]);

  return (
    <Button
      variant="text"
      aria-label={t("ariaLabels.changeLanguage")}
      sx={{ color: theme.palette.primary.contrastText }}
      onClick={() => handleLanguageChange(getAlternateLang())}
    >
      {i18n.language === "en" ? "FI" : "EN"}
    </Button>
  );
};

export default LanguageToggleButton;
