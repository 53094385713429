import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import LazyLoadBackground from "./LazyLoadBackground";
import { HeaderImageProps } from "../shared-types";

const HeaderImage: React.FC<HeaderImageProps> = ({
  imgUrl,
  alt,
  pageTitle,
  pageSubtitle,
  hotspot,
  height = 400,
  blackAndWhite = false,
  mainHeading = false,
}) => {
  const theme = useTheme();
  const textMaxWidth = "1000px";
  const mainHeadingType = mainHeading ? "h1" : "h2";

  return (
    <LazyLoadBackground
      imgURL={imgUrl}
      alt={alt}
      hotspot={hotspot}
      sx={{
        position: "relative",
        width: "100%",
        height: { xs: height, xl: height + 200 },
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        filter: blackAndWhite ? "grayscale(100%)" : "",
      }}
    >
      {/* Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: theme.palette.overlay.dark,
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: 4,
          paddingTop: 12,
          paddingBottom: 2,
        }}
      >
        <Typography
          variant={mainHeadingType}
          sx={{
            position: "relative",
            color: theme.palette.primary.contrastText,
            zIndex: 1,
            textAlign: "center",
            fontWeight: "bold",
            maxWidth: textMaxWidth,
          }}
        >
          {pageTitle}
        </Typography>

        <Typography
          variant="h2"
          marginTop={4}
          sx={{
            fontSize: { xs: "1.125rem", md: "1.5rem" },
            position: "relative",
            marginTop: 2,
            fontWeight: 400,
            color: theme.palette.primary.contrastText,
            zIndex: 1,
            textAlign: "center",
            maxWidth: textMaxWidth,
          }}
        >
          {pageSubtitle}
        </Typography>
      </Box>
    </LazyLoadBackground>
  );
};

export default HeaderImage;
