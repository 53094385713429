import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, IconButton, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import images from "../utils/images";
import { Testimonial } from "../shared-types";
import { fetchTestimonials } from "../utils/sanityUtils";

const Testimonials: React.FC = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const totalTestimonials = testimonials.length;

  // Fetch testimonials when language changes
  useEffect(() => {
    fetchTestimonials(i18n.language).then((testimonialsFromSanity) => {
      setTestimonials(testimonialsFromSanity);
    });
  }, [i18n.language]);

  const goToNextTestimonial = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalTestimonials);
  }, [totalTestimonials]);

  const goToPreviousTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? totalTestimonials - 1 : prevIndex - 1,
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) {
        goToNextTestimonial();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [goToNextTestimonial, isHovered]);

  const navButtonsSxProps = {
    backgroundColor: theme.palette.beige.light,
    borderRadius: "50%",
    boxShadow: `0 1px 5px ${theme.palette.overlay.dark}`,
    "&:hover": { background: theme.palette.beige.light },
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        margin: "0 auto",
        minHeight: "600px",
        backgroundImage: `url(${images.layeredWaves1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          position: "relative",
          maxWidth: { xs: "100%", md: "700px" },
          width: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          paddingBottom: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            transition: "transform 0.5s ease-in-out",
            width: `${totalTestimonials * 100}%`, // Set width based on the number of testimonials
            transform: `translateX(-${currentIndex * (100 / totalTestimonials)}%)`, // Adjust for current index
          }}
        >
          {testimonials.map((testimonial, index) => (
            <Box
              key={index}
              sx={{
                width: `${100 / totalTestimonials}%`, // Each testimonial takes up an equal width
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                opacity: 1,
                textAlign: "center",
              }}
            >
              <Typography
                aria-label={t("ariaLabels.testimonialText")}
                variant="body1"
                sx={{
                  fontStyle: "italic",
                  color: theme.palette.primary.contrastText,
                  maxWidth: { xs: "70%", md: "600px" },
                }}
              >
                {testimonial.text}
              </Typography>
              <Typography
                aria-label={t("ariaLabels.testimonialAuthor")}
                variant="body1"
                sx={{ color: theme.palette.beige.light, marginTop: 2 }}
              >
                – {testimonial.author}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Container for the icon buttons */}
      <Box
        sx={{
          position: "absolute",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "calc(100% - 32px)",
          maxWidth: { xs: "100%", md: "900px" },
          padding: 2,
        }}
      >
        {/* Left Navigation Button */}
        <IconButton
          aria-label={t("ariaLabels.showPreviousTestimonial")}
          sx={navButtonsSxProps}
          onClick={goToPreviousTestimonial}
        >
          <ArrowBackIosIcon
            sx={{
              transform: { xs: "translateX(3px)", md: "translateX(5px)" },
              color: theme.palette.orange.main,
              width: { xs: 16, md: 24 },
              height: { xs: 16, md: 24 },
            }}
          />
        </IconButton>

        {/* Right Navigation Button */}
        <IconButton
          aria-label={t("ariaLabels.showNextTestimonial")}
          sx={navButtonsSxProps}
          onClick={goToNextTestimonial}
        >
          <ArrowForwardIosIcon
            sx={{
              color: theme.palette.orange.main,
              width: { xs: 16, md: 24 },
              height: { xs: 16, md: 24 },
            }}
          />
        </IconButton>
      </Box>

      {/* Dots Indicator */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 2,
        }}
      >
        {testimonials.map((_, index) => (
          <Box
            aria-label={`${t("ariaLabels.showTestimonialAt")} ${index + 1}.`}
            component={"button"}
            key={index}
            sx={{
              height: 10,
              width: 10,
              border: "none",
              padding: 0,
              borderRadius: "50%",
              backgroundColor:
                currentIndex === index
                  ? theme.palette.orange.main
                  : theme.palette.beige.light,
              margin: "0 4px",
              cursor: "pointer",
              transition: "background-color 0.3s ease-in-out",
            }}
            onClick={() => setCurrentIndex(index)} // Navigate to specific comment on dot click
          />
        ))}
      </Box>
    </Box>
  );
};

export default Testimonials;
