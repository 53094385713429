import React from "react";
import { IconButton, useTheme } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { phoneNumber } from "../constants";
import { useTranslation } from "react-i18next";

const WhatsAppButton: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <IconButton
      component="a"
      aria-label={t("ariaLabels.sendMeWhatsApp")}
      href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=`}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        position: "fixed",
        bottom: "16px",
        left: "16px",
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        backgroundColor: "#25d367",
        "&:hover": { backgroundColor: "#25d367" },
        color: theme.palette.white.regular,
        zIndex: 2,
      }}
    >
      <WhatsAppIcon sx={{ fontSize: "26px" }} />
    </IconButton>
  );
};

export default WhatsAppButton;
