import React from "react";
import { Box, Typography, IconButton, Collapse, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FAQ } from "../shared-types";

interface FAQItemProps extends FAQ {
  open: boolean;
  onToggle: () => void;
}

const FAQItem: React.FC<FAQItemProps> = ({
  question,
  answer,
  open,
  onToggle,
}) => {
  const theme = useTheme();

  return (
    <Box
      key={question}
      mb={4}
      pb={1}
      sx={{
        maxWidth: { sm: "800px" },
        marginLeft: "auto",
        marginRight: "auto",
        borderBottom: `2px solid ${theme.palette.beige.background}`,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={onToggle}
        sx={{ cursor: "pointer", padding: "8px 0" }}
      >
        <Typography variant="h6" sx={{ paddingRight: 2 }}>
          {question}
        </Typography>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onToggle();
          }}
          sx={{
            background: "transparent",
            "&:hover": { background: "transparent" },
          }}
        >
          <ExpandMoreIcon
            sx={{
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
              color: theme.palette.orange.main,
            }}
          />
        </IconButton>
      </Box>
      <Collapse in={open}>
        <Typography
          variant="body2"
          sx={{ mt: 1, paddingBottom: 2, color: theme.palette.primary.dark }}
        >
          {answer}
        </Typography>
      </Collapse>
    </Box>
  );
};

export default FAQItem;
