import { imageNotFoundFallback } from "../constants";
import i18n from "../i18n";
import { createClient } from "@sanity/client";
import {
  ClassExplanation,
  ClassInfo,
  FAQ,
  GetToKnowMe,
  HomeContent,
  PricingItem,
  SanityImage,
  Service,
  Testimonial,
  WhyMePoints,
} from "../shared-types";

const client = createClient({
  projectId: process.env.REACT_APP_SANITY_STUDIO_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_STUDIO_DATASET,
  useCdn: true,
  apiVersion: process.env.REACT_APP_SANITY_STUDIO_API_VERSION,
  token: process.env.REACT_APP_SANITY_STUDIO_TOKEN,
  withCredentials: true,
});

const currentDay = new Date().toISOString().split("T")[0]; // YYYY-MM-DD format

// Generic function for fetching data with type safety
const fetchData = async <T>(
  query: string,
  cacheKey: string,
  fallbackData: T,
): Promise<T> => {
  try {
    const dataFromCache: T | null = checkForCachedData<T>(cacheKey);
    if (dataFromCache) {
      return dataFromCache;
    }

    const data: T = await client.fetch<T>(query);
    setToLocalStorage(cacheKey, data);
    return data;
  } catch (error) {
    console.error(`Error fetching data for ${cacheKey}:`, error);
    return fallbackData;
  }
};

export const fetchHomeContent = (language: string) => {
  const errorType: HomeContent = {
    pageTitle: i18n.t("somethingWentWrongTitle"),
    pageSubtitle: i18n.t("somethingWentWrongDescription"),
    welcomeTitle: i18n.t("somethingWentWrongTitle"),
    welcomeBodyText: i18n.t("somethingWentWrongDescription"),
    landingImage: {
      imageUrl: imageNotFoundFallback,
      alt: "Something didn't work somewhere",
    },
  };

  if (!language) {
    return Promise.resolve(errorType);
  }

  return fetchData<HomeContent>(
    `*[_type == "homeContent"][0] {
      "pageTitle": pageTitle.${language},
      "pageSubtitle": pageSubtitle.${language},
      "welcomeTitle": welcomeTitle.${language},
      "welcomeBodyText": welcomeBodyText.${language},
      "landingImage": landingImage{
        "imageUrl": asset-> url,
        hotspot,
        alt
      }
    }`,
    `homeContent_${language}`,
    errorType,
  );
};

export const fetchFAQs = (language: string) => {
  const errorType = {
    question: i18n.t("somethingWentWrongTitle"),
    answer: i18n.t("somethingWentWrongDescription"),
  };

  if (!language) {
    return Promise.resolve([errorType]);
  }

  return fetchData<FAQ[]>(
    `*[_type == "faq"] | order(orderRank) { "question": Question.${language}, "answer": Answer.${language} }`,
    `faqs_${language}`,
    [errorType],
  );
};

export const fetchServices = (language: string) => {
  const errorType: Service = {
    type: "yoga",
    serviceTitle: i18n.t("somethingWentWrongTitle"),
    description: i18n.t("somethingWentWrongDescription"),
    duration: "Hups",
    image: {
      imageUrl: imageNotFoundFallback,
      alt: "Something didn't work somewhere",
    },
  };

  if (!language) {
    return Promise.resolve([errorType]);
  }

  return fetchData<Service[]>(
    `*[_type == "serviceItem"] | order(orderRank) {
      "type": type,
      "image": serviceImage{
        "imageUrl": asset-> url,
        hotspot,
        alt
      },
      "serviceTitle": serviceName.${language},
      "description": serviceDescription.${language},
      "intensity": serviceIntensity.${language},
      "duration": serviceDuration.${language},
      "maxAttendees": maxAttendees.${language}
    }`,
    `serviceItems_${language}`,
    [errorType],
  );
};

export const fetchClassExplanations = (language: string) => {
  const errorType = {
    classTitle: i18n.t("somethingWentWrongTitle"),
    description: i18n.t("somethingWentWrongDescription"),
  };

  if (!language) {
    return Promise.resolve([errorType]);
  }

  return fetchData<ClassExplanation[]>(
    `*[_type == "classExplanations"] | order(orderRank) {
      "classTitle": classTitle.${language},
      "description": explanation.${language},
      "items": explanationList[].explanationItem.${language}
    }`,
    `classExplanations_${language}`,
    [errorType],
  );
};

export const fetchPricingList = (language: string) => {
  const errorType: PricingItem = {
    type: "yoga",
    plan: i18n.t("somethingWentWrongTitle"),
    description: i18n.t("somethingWentWrongDescription"),
    price: 0,
  };

  if (!language) {
    return Promise.resolve([errorType]);
  }

  return fetchData<PricingItem[]>(
    `*[_type == "pricingItem"] | order(orderRank) { "plan": plan.${language}, "description": description.${language},
    "price": price, "type": type }`,
    `pricingItems_${language}`,
    [errorType],
  );
};

export const fetchSchedule = (language: string) => {
  const errorType: ClassInfo = {
    className: i18n.t("somethingWentWrongTitle"),
    time: "00:00 - 00:00",
    intensity: 0,
    day: i18n.t("somethingWentWrongDescription"),
  };

  if (!language) {
    return Promise.resolve([errorType]);
  }

  return fetchData<ClassInfo[]>(
    `*[_type == "scheduleItem"] | order(orderRank) { "className": className->name, "time": time,
    "intensity": intensity, "day": weekday }`,
    `scheduleItems_${language}`,
    [errorType],
  );
};

export const fetchTestimonials = (language: string) => {
  const errorType = {
    text: i18n.t("somethingWentWrongTitle"),
    author: i18n.t("somethingWentWrongDescription"),
  };

  if (!language) {
    return Promise.resolve([errorType]);
  }

  return fetchData<Testimonial[]>(
    `*[_type == "testimonial"] | order(orderRank) { "text": text.${language}, "author": author }`,
    `testimonials_${language}`,
    [errorType],
  );
};

export const fetchWhyMe = (language: string) => {
  const errorType = {
    point: i18n.t("somethingWentWrongTitle"),
    description: i18n.t("somethingWentWrongDescription"),
  };

  if (!language) {
    return Promise.resolve([errorType]);
  }

  return fetchData<WhyMePoints[]>(
    `*[_type == "whyMe"] | order(orderRank) { "point": Point.${language}, "description": Description.${language} }`,
    `whyMe_${language}`,
    [errorType],
  );
};

export const fetchScheduleImage = () =>
  fetchData<SanityImage>(
    `*[_type == "scheduleImage"][0] {
      "imageUrl": scheduleImage.asset->url,
      "alt": scheduleImage.alt,
      "hotspot": scheduleImage.hotspot
    }`,
    `scheduleImage`,
    {
      imageUrl: "",
      alt: "",
    },
  );

export const fetchWhyMeImage = () =>
  fetchData<SanityImage>(
    `*[_type == "whyMeImage"][0] {
      "imageUrl": whyMeImage.asset->url,
      "alt": whyMeImage.alt,
      "hotspot": whyMeImage.hotspot
    }`,
    `whyMeImage`,
    {
      imageUrl: "",
      alt: "",
    },
  );

export const fetchInstagramPosts = () =>
  fetchData<SanityImage[]>(
    `*[_type == "instagramPosts"] | order(orderRank) {
      "imageUrl": instagramPost.asset->url,
      "alt": instagramPost.alt
    }`,
    `instagramPosts`,
    [
      {
        imageUrl: "",
        alt: "",
      },
    ],
  );

export const fetchGetToKnowMe = (language: string) => {
  const errorType = {
    text: i18n.t("somethingWentWrongTitle"),
    image: {
      imageUrl: imageNotFoundFallback,
      alt: "Image",
    },
  };

  if (!language) {
    return Promise.resolve(errorType);
  }

  return fetchData<GetToKnowMe>(
    `*[_type == "getToKnowMe"][0] {
      "text": getToKnowMeText.${language},
      "image": {
        "imageUrl": getToKnowMeImage.asset->url,
        "alt": getToKnowMeImage.alt,
        "hotspot": getToKnowMeImage.hotspot
      }
    }`,
    `getToKnowMe_${language}`,
    errorType,
  );
};

// Utility functions
const setToLocalStorage = <T>(cacheKey: string, data: T) => {
  localStorage.setItem(
    cacheKey,
    JSON.stringify({
      timestamp: currentDay,
      data: data,
    }),
  );
};

const checkForCachedData = <T>(cacheKey: string): T | null => {
  const cachedData = localStorage.getItem(cacheKey);

  if (cachedData) {
    const parsedCache = JSON.parse(cachedData) as {
      timestamp: string;
      data: T;
    };
    if (parsedCache.timestamp === currentDay) {
      return parsedCache.data;
    }
  }

  return null;
};
