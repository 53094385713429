import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FAQItem from "./FAQItem";
import Collapsible from "./Collapsible";
import { FAQ } from "../shared-types";
import { fetchFAQs } from "../utils/sanityUtils";

interface FAQContentProps {
  parentRef?: React.RefObject<HTMLElement>;
}

const FAQContent: React.FC<FAQContentProps> = ({ parentRef }) => {
  const { t, i18n } = useTranslation();
  const [faqs, setFaqs] = useState<FAQ[]>([]);

  // State to keep track of the currently opened FAQ index
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    // If the clicked index is already open, close it; otherwise, open the new one
    setOpenIndex(openIndex === index ? null : index);
  };

  // Fetch faqs when language changes
  useEffect(() => {
    fetchFAQs(i18n.language).then((faqsFromSanity) => {
      setFaqs(faqsFromSanity);
    });
  }, [i18n.language]);

  return (
    <Collapsible
      parentRef={parentRef}
      collapsedHeight={280}
      buttonLabels={{
        readMore: t("showMore"),
        showLess: t("showLess"),
      }}
    >
      {faqs &&
        faqs.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            open={openIndex === index}
            onToggle={() => handleToggle(index)}
          />
        ))}
    </Collapsible>
  );
};

export default FAQContent;
