export const phoneNumber = "358452745212";
export const emailAddress = "wellinyoga@gmail.com";
export const streetAddress = "Pirkankatu 11, Tampere";
export const baseUrl = `${window.location.protocol}//${window.location.host}`;
export const imageNotFoundFallback = "/dont-have-it.webp";

export const filterPrimaryDark =
  "brightness(0) saturate(100%) invert(23%) sepia(38%) saturate(210%) hue-rotate(113deg) brightness(99%) contrast(95%)"; // filter for #344641
export const filterBeigeLight =
  "brightness(0) saturate(100%) invert(92%) sepia(10%) saturate(1241%) hue-rotate(321deg) brightness(108%) contrast(101%)"; // filter for #FFEECA
export const brandLinesSxProps = {
  display: "flex",
  width: "50px",
  height: "40px",
  backgroundImage: 'url("/wellinyoga-brand-lines.png")',
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  filter: filterPrimaryDark,
};
