import React from "react";
import PricingList from "../components/Pricing/PricingList";
import { Box } from "@mui/material";
import images from "../utils/images";
import { brandLinesSxProps, filterBeigeLight } from "../constants";

const Pricing: React.FC = () => {
  return (
    <Box
      className="pageWrapper"
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${images.layeredWaves3})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <PricingList />
      {/* Brandlines graphic */}
      <Box
        sx={{
          ...brandLinesSxProps,
          position: "absolute",
          bottom: 20,
          right: 20,
          filter: filterBeigeLight,
        }}
      />
    </Box>
  );
};

export default Pricing;
