import React, { useEffect, useState } from "react";
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { PricingItem } from "../../shared-types";
import { fetchPricingList } from "../../utils/sanityUtils";

const PricingList: React.FC = () => {
  const { t, i18n } = useTranslation(); // Get the t function for translations
  const theme = useTheme();
  const [data, setData] = useState<PricingItem[]>([]);
  const [pricesWithCategories, setPricesWithCategories] =
    useState<boolean>(false);

  // Fetch data when language changes
  useEffect(() => {
    fetchPricingList(i18n.language).then((dataFromSanity: PricingItem[]) => {
      setData(dataFromSanity);
      renderTitle(dataFromSanity);
    });
  }, [i18n.language]);

  const addPricingItem = (item: PricingItem, index: number) => {
    return (
      <Paper
        key={`${item} + ${index}`}
        sx={{
          padding: 2,
          backgroundColor: theme.palette.white.regular,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Box>
          <Typography variant="h6" alignSelf={"flex-start"}>
            {item.plan}
          </Typography>
          <Typography
            variant="body2"
            sx={{ paddingRight: 1, flexGrow: 1, alignSelf: "flex-start" }}
          >
            {item.description}
          </Typography>
        </Box>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: theme.palette.primary.main,
            lineHeight: "inherit",
            paddingLeft: 1,
          }}
        >
          {item.price}€
        </Typography>
      </Paper>
    );
  };

  // Check whether there are both yogas and massages in the data
  const renderTitle = (pricingData: PricingItem[]) => {
    const types = new Set(pricingData.map((item) => item.type));
    if (types.size === 1 && types.has("yoga")) {
      setPricesWithCategories(false); // One title for only yoga items
    } else {
      setPricesWithCategories(true); // Both subtitles visible
    }
  };

  const subtitleSxProps = {
    fontFamily: "Dahlia, Arial, sans-serif",
    textAlign: "center",
    fontSize: { xs: "2.25rem", md: "3rem" },
  };

  return (
    <Box
      maxWidth={600}
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      gap={4}
    >
      {pricesWithCategories ? (
        <>
          <Typography variant="h2" sx={subtitleSxProps}>
            {t("pageTitles.pricing")} – {t("yogaTitle")}
          </Typography>
          {data.map((item, index) => {
            return item.type === "yoga" ? addPricingItem(item, index) : <></>;
          })}

          <Typography
            variant="h2"
            sx={{
              ...subtitleSxProps,
              paddingTop: 6,
              color: theme.palette.beige.main,
            }}
          >
            {t("pageTitles.pricing")} – {t("massageTitle")}
          </Typography>
          {data.map((item, index) => {
            return item.type === "massage" ? (
              addPricingItem(item, index)
            ) : (
              <></>
            );
          })}
        </>
      ) : (
        <>
          <Typography
            variant="h2"
            sx={{ fontFamily: "Dahlia, Arial, sans-serif" }}
          >
            {t("pageTitles.pricing")}
          </Typography>
          {data.map((item, index) => addPricingItem(item, index))}
        </>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 8,
          marginBottom: 4,
        }}
      >
        <Button
          component={"a"}
          href="https://vello.fi/wellinyoga/service"
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`${t("ariaLabels.bookInVello")}`}
          variant="contained"
          sx={{
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.beige.main,
          }}
        >
          {t("bookInVello")}
          <OpenInNewIcon sx={{ fontSize: "12px", paddingLeft: 1 }} />
        </Button>
      </Box>
    </Box>
  );
};

export default PricingList;
