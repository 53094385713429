import { Box, Typography, IconButton, useTheme } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import images from "../utils/images";
import Collapsible from "../components/Collapsible";
import LazyLoadBackground from "../components/LazyLoadBackground";
import { fetchGetToKnowMe } from "../utils/sanityUtils";
import { GetToKnowMe } from "../shared-types";
import { imageNotFoundFallback } from "../constants";

const About: React.FC = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [data, setData] = useState<GetToKnowMe>({
    text: i18n.t("fetchingData"),
    image: {
      imageUrl: imageNotFoundFallback,
      alt: "Image",
    },
  });

  // Fetch data when language changes
  useEffect(() => {
    fetchGetToKnowMe(i18n.language).then((dataFromSanity: GetToKnowMe) => {
      setData(dataFromSanity);
    });
  }, [i18n.language]);

  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      ref={parentRef}
      className="pageWrapper"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      sx={{
        backgroundImage: `url(${images.blobScene2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingBottom: 4,
      }}
    >
      <Box
        maxWidth={{ xs: 500, lg: 700 }}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Typography
          variant="h2"
          paddingBottom={4}
          sx={{
            fontFamily: "Dahlia, Arial, sans-serif",
            textAlign: "center",
          }}
        >
          {t("pageTitles.about")}
        </Typography>

        <Collapsible
          collapsedHeight={140}
          buttonLabels={{
            readMore: t("readMore"),
            showLess: t("showLess"),
          }}
          parentRef={parentRef}
        >
          <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
            {data && data.text}
          </Typography>

          <LazyLoadBackground
            imgURL={data.image.imageUrl}
            alt={data.image.alt}
            hotspot={data.image.hotspot}
            sx={{
              margin: "0 auto",
              marginTop: 4,
              marginBottom: 0,
              maxWidth: { xs: "250px", sm: "300px", md: "400px" },
              height: { xs: "375px", sm: "450px", md: "600px" },
              borderRadius: "8px",
              boxShadow: `0px 4px 8px ${theme.palette.overlay.dark}`,
            }}
          />
        </Collapsible>
      </Box>

      {/* Social Media Icons */}
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          paddingTop: 8,
        }}
      >
        <IconButton
          component="a"
          aria-label={t("ariaLabels.seeMyInstagram")}
          href="https://www.instagram.com/wellinyoga"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: theme.palette.orange.main,
            backgroundColor: "transparent",
            padding: 2,
            borderRadius: "8px",
          }}
        >
          <InstagramIcon />
        </IconButton>

        <IconButton
          component="a"
          aria-label={t("ariaLabels.seeMyFacebook")}
          href="https://www.facebook.com/denniswellingtoneyoga/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: theme.palette.orange.main,
            backgroundColor: "transparent",
            padding: 2,
            borderRadius: "8px",
          }}
        >
          <FacebookIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default About;
