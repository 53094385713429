import { useEffect } from "react";

export const ClearLocalStorage: React.FC = () => {
  useEffect(() => {
    localStorage.clear();
    alert(
      "Local storage cache has been cleared. You should see the new content now. If not, CALL JANI :'D",
    );
    window.location.href = "/";
  }, []);

  return null;
};
