import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import images from "../utils/images";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LazyLoadBackgroundImage from "../components/LazyLoadBackground";
import { SanityImage, WhyMePoints } from "../shared-types";
import { fetchWhyMe, fetchWhyMeImage } from "../utils/sanityUtils";
import { imageNotFoundFallback } from "../constants";

const WhyMe: React.FC = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [pointsData, setPointsData] = useState<WhyMePoints[]>([]);
  const [imageData, setImageData] = useState<SanityImage>({
    imageUrl: imageNotFoundFallback,
    alt: "Something didn't work somewhere",
  });

  // Fetch points when language changes
  useEffect(() => {
    fetchWhyMe(i18n.language).then((dataFromSanity: WhyMePoints[]) => {
      setPointsData(dataFromSanity);
    });
  }, [i18n.language]);

  // Fetch image
  useEffect(() => {
    fetchWhyMeImage().then((dataFromSanity: SanityImage) => {
      if (dataFromSanity.imageUrl) {
        setImageData(dataFromSanity);
      }
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Stack on small screens
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 8,
        paddingBottom: 8,
        backgroundImage: `url(${images.beigeMainBeigeLightGradient})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Left side with bullet points */}
      <Box
        sx={{
          width: { md: "50%" },
          paddingTop: { md: 8 },
          paddingBottom: 8,
          paddingRight: 2,
        }}
      >
        <Typography
          variant="h2"
          paddingBottom={4}
          sx={{
            fontFamily: "Dahlia, Arial, sans-serif",
            textAlign: "center",
          }}
        >
          {t("pageTitles.whyMe")}
        </Typography>
        <List>
          {pointsData &&
            pointsData.map((pointItem, index) => (
              <ListItem
                key={index}
                alignItems="flex-start"
                sx={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 4 }}
              >
                <ListItemIcon sx={{ marginTop: 0.5 }}>
                  <CheckCircleIcon sx={{ color: theme.palette.orange.main }} />
                </ListItemIcon>
                <Box>
                  <Typography variant="h6">{pointItem.point}</Typography>
                  <Typography variant="body2">
                    {pointItem.description}
                  </Typography>
                </Box>
              </ListItem>
            ))}
        </List>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 2, // Add some space above the button
          }}
        >
          <Button
            component={"a"}
            href="https://vello.fi/wellinyoga/service"
            target="_blank"
            rel="noopener noreferrer"
            aria-label={t("ariaLabels.bookInVello")}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
          >
            {t("bookInVello")}
            <OpenInNewIcon sx={{ fontSize: "12px", paddingLeft: 1 }} />
          </Button>
        </Box>
      </Box>

      {/* Right side with the image */}
      <LazyLoadBackgroundImage
        imgURL={imageData.imageUrl}
        alt={imageData.alt}
        hotspot={imageData.hotspot}
        sx={{
          width: { xs: "300px", lg: "400px" },
          height: { xs: "525px", lg: "700px" },
          borderRadius: "8px",
          boxShadow: `0px 4px 8px ${theme.palette.overlay.dark}`,
        }}
      />
    </Box>
  );
};

export default WhyMe;
