import { useEffect, useState, forwardRef } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import { emailAddress, streetAddress } from "../../constants";
import LanguageToggleButton from "./LanguageToggleButton";
import MobileDrawer from "./MobileDrawer";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";
import { navItems } from "../../utils/utils";
import debounce from "lodash/debounce";
import images from "../../utils/images";
import { useHistoryState } from "../ScrollTracker";

interface HeaderProps {
  isProgrammaticScroll: boolean;
  handleNavClick: (path: string) => void;
}

const Header = forwardRef<HTMLElement, HeaderProps>(
  ({ isProgrammaticScroll, handleNavClick }, ref) => {
    const theme = useTheme();
    const isMobile = useMediaQuery("(max-width: 1100px)");
    const isTinyMobile = useMediaQuery("(max-width: 460px)");
    const headerMinHeight = 64;
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const visibleUrl = useHistoryState(); // Get the visible URL from the custom hook

    // State to manage header visibility
    const [isHeaderVisible, setHeaderVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(true);
    const [activePath, setActivePath] = useState("");

    // Check and set the active section based on URL
    useEffect(() => {
      const paths = i18n.getResource(
        i18n.language,
        "translation",
        "paths",
      ) as Record<string, string>;

      // Find the active value and use its key as active path
      for (const [key, value] of Object.entries(paths)) {
        if (visibleUrl === value) {
          setActivePath(`paths.${key}`);
        }
      }
    }, [visibleUrl, location.pathname, i18n]);

    // Handle scroll events
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // If at the top or at the end of the page always show header
      // If scrolling was triggered programmatically
      // Or the user scrolled up
      if (
        currentScrollY === 0 ||
        currentScrollY + window.innerHeight >=
          document.documentElement.scrollHeight ||
        isProgrammaticScroll ||
        (currentScrollY < lastScrollY && currentScrollY > 30)
      ) {
        setHeaderVisible(true);
      } else if (currentScrollY > lastScrollY + 200) {
        // User scrolling down
        setHeaderVisible(false);
        setSubHeaderVisible(false);
      }

      // Set subHeader visible when at the top of the page or at the bottom of the page
      if (
        currentScrollY === 0 ||
        currentScrollY + window.innerHeight >=
          document.documentElement.scrollHeight
      ) {
        setSubHeaderVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    // Debounce the handleScroll function using Lodash
    const debouncedHandleScroll = debounce(handleScroll, 20);

    // Add event listener for scroll events
    useEffect(() => {
      window.addEventListener("scroll", debouncedHandleScroll);
      return () => {
        window.removeEventListener("scroll", debouncedHandleScroll);
      };
    }, [debouncedHandleScroll]);

    return (
      <AppBar
        ref={ref}
        position="fixed"
        sx={{
          transition: "transform 0.3s ease-in-out, height 0.3s ease-in-out",
          transform: isHeaderVisible ? "translateY(0)" : "translateY(-100%)",
          background: `linear-gradient(340deg, #4e6961 23%, ${theme.palette.primary.main} 70%)`,
          width: "100vw",
          left: 0,
          height: {
            xs: isSubHeaderVisible ? "120px" : headerMinHeight,
            sm: isSubHeaderVisible ? "104px" : headerMinHeight,
          },
        }}
      >
        <Toolbar sx={{ minHeight: headerMinHeight }}>
          {/* Logo / brand name */}
          <Box style={{ flexGrow: 1 }}>
            <Button
              aria-label={t("ariaLabels.toHome")}
              component={NavLink}
              to={t("paths.home")}
              style={{ textDecoration: "none", padding: 0 }}
            >
              <img
                src={images.logo}
                alt={`${t("siteTitle")} ${t("pageTitles.home")}`}
                style={{ height: headerMinHeight }}
              />
            </Button>
          </Box>

          {isMobile ? (
            // Mobile Drawer for small screens
            <MobileDrawer activePath={activePath} />
          ) : (
            // Regular navigation for desktop
            <Box
              component="nav"
              aria-label={t("ariaLabels.mainNavigation")}
              sx={{ display: "flex", gap: 2 }}
            >
              {navItems.map(
                (item) =>
                  item.desktop && (
                    <Button
                      key={item.path}
                      component={Link}
                      to={t(item.path)}
                      aria-label={`${t("ariaLabels.goTo")} ${t(item.title)}`}
                      onClick={() => handleNavClick(t(item.path))}
                      sx={{
                        position: "relative",
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        color:
                          item.path === activePath
                            ? theme.palette.secondary.main
                            : theme.palette.primary.contrastText,
                        "&:hover": {
                          backgroundColor: theme.palette.primary.light,
                        },
                        "&:after": {
                          content: "''",
                          display: "block",
                          position: "absolute",
                          bottom: 0,
                          left: "50%",
                          transform: "translateX(-50%)",
                          width: item.path === activePath ? "100%" : "0px",
                          height: "100%",
                          borderBottom: `2px solid ${theme.palette.secondary.main}`,
                          transition: "width 0.3s ease-in-out",
                        },
                      }}
                    >
                      {t(item.title)}
                    </Button>
                  ),
              )}
            </Box>
          )}

          <LanguageToggleButton />
        </Toolbar>

        {/* Box with text and contact icons below header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: theme.palette.beige.main,
            background: `linear-gradient(12deg, #f7e9ca 32%, ${theme.palette.beige.main} 70%)`,
            border: "none",
            padding: 1,
            borderRadius: "4px",
            flexGrow: 1,
            opacity: isSubHeaderVisible ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
            pointerEvents: isSubHeaderVisible ? "all" : "none",
          }}
        >
          {/* Info Icon and text */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: 1,
              width: isTinyMobile ? "200px" : "initial",
            }}
          >
            <InfoIcon
              sx={{ color: theme.palette.orange.main, marginRight: 1 }}
            />
            <Typography
              variant="body2"
              sx={{ color: theme.palette.primary.dark }}
            >
              {t("openByScheduleAndByAppointment")}
            </Typography>
          </Box>

          {/* Address and Email */}
          <Box
            component={"address"}
            display="flex"
            alignItems="center"
            width={{ xs: "130px", sm: "auto" }}
            fontStyle={"normal"}
          >
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: 2,
                color: theme.palette.primary.dark,
                textDecoration: "none",
              }}
              component={"a"}
              href="https://maps.app.goo.gl/rPkd2MQGFUsTt4vf9"
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`${t("ariaLabels.openGoogleMapsTo")} ${streetAddress}`}
            >
              <LocationOnIcon
                sx={{ marginRight: 1, color: theme.palette.orange.main }}
              />
              {streetAddress}
            </Typography>

            <Typography
              variant="body2"
              sx={{
                alignItems: "center",
                cursor: "pointer",
                marginLeft: 2,
                color: theme.palette.primary.dark,
                display: { xs: "none", md: "flex" },
                textDecoration: "none",
              }}
              component={"a"}
              href={`mailto:${emailAddress}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`${t("ariaLabels.openEmailAppTo")} ${emailAddress}`}
            >
              <MailIcon
                sx={{ marginRight: 1, color: theme.palette.orange.main }}
              />
              {emailAddress}
            </Typography>
          </Box>
        </Box>
      </AppBar>
    );
  },
);

export default Header;
