import blobScene from "./../images/bg/blob-scene.svg";
import blobScene2 from "./../images/bg/blob-scene-2.svg";
import beigeMainBeigeLightGradient from "./../images/bg/beige-main-beige-light-gradient.svg";
import beigeMainBeigeDarkGradientVertical from "./../images/bg/beige-main-beige-dark-gradient-vertical.svg";
import verticalStackedWaves from "./../images/bg/vertical-stacked-waves.svg";
import layeredWaves1 from "./../images/bg/layered-waves-1.svg";
import layeredWaves2 from "./../images/bg/layered-waves-2.svg";
import layeredWaves3 from "./../images/bg/layered-waves-3.svg";
import threeSimpleWaves from "./../images/bg/three-simple-waves.svg";
import greenDownwardWave from "./../images/bg/green-downward-wave.svg";
import edenredLogo from "./../images/logos/edenred-logo.png";
import velloLogo from "./../images/logos/vello-logo.png";
import smartumPayLogo from "./../images/logos/smartum-pay-logo.svg";
import epassiLogo from "./../images/logos/epassi-logo.png";

// Define the path to the logo in the public folder
const logo = `${process.env.PUBLIC_URL}/wellinyoga-logo.webp`;

const images = {
  logo,
  velloLogo,
  edenredLogo,
  smartumPayLogo,
  epassiLogo,
  blobScene,
  blobScene2,
  beigeMainBeigeLightGradient,
  beigeMainBeigeDarkGradientVertical,
  verticalStackedWaves,
  layeredWaves1,
  layeredWaves2,
  layeredWaves3,
  threeSimpleWaves,
  greenDownwardWave,
};

export default images;
