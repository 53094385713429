import { Box, Typography, useTheme } from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import FAQContent from "../components/FAQContent";
import { brandLinesSxProps } from "../constants";

const FAQ: React.FC = () => {
  const { t } = useTranslation(); // Get the t function for translations
  const parentRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  return (
    <Box
      ref={parentRef}
      className="pageWrapper"
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: 4,
        background: `linear-gradient(160deg, ${theme.palette.beige.light} 19%, ${theme.palette.beige.main} 91%)`,
      }}
    >
      {/* Brandlines graphic */}
      <Box
        sx={{
          ...brandLinesSxProps,
          position: "absolute",
          top: 20,
          left: 20,
        }}
      />
      <Box
        sx={{
          ...brandLinesSxProps,
          position: "absolute",
          bottom: 20,
          right: 20,
        }}
      />
      <Typography
        variant="h2"
        sx={{
          paddingBottom: 8,
          fontFamily: "Dahlia, Arial, sans-serif",
          textAlign: "center",
        }}
      >
        {t("FAQPageTitle")}
      </Typography>
      <FAQContent parentRef={parentRef} />
    </Box>
  );
};

export default FAQ;
