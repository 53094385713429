import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ClassExplanation } from "../../shared-types";
import { fetchClassExplanations } from "../../utils/sanityUtils";

const ClassExplanations: React.FC = () => {
  const { i18n } = useTranslation();
  const [data, setData] = useState<ClassExplanation[]>([
    {
      classTitle: i18n.t("fetchingData"),
      description: i18n.t("fetchingData"),
      items: [],
    },
  ]);

  // Fetch class explanations when language changes
  useEffect(() => {
    fetchClassExplanations(i18n.language).then(
      (dataFromSanity: ClassExplanation[]) => {
        setData(dataFromSanity);
      },
    );
  }, [i18n.language]);

  const boxSxProps = {
    maxWidth: { xs: 300, lg: 220, xl: 300 },
    flex: { md: "1 0 50%", lg: "1 0 25%" },
    padding: 0,
    margin: 0,
  };

  return (
    <Box
      sx={{
        padding: 4,
        display: "flex",
        gap: { xs: 6, md: 8 },
        flexDirection: { xs: "column", md: "row" },
        maxWidth: { xs: "80%", md: "800px", lg: "1100px", xl: "1400px" },
        flexWrap: "wrap",
      }}
      justifyContent={"center"}
    >
      {data.map((item) => (
        <Box
          component={item.items && item.items.length !== 0 ? "ul" : "div"}
          key={item.classTitle}
          sx={boxSxProps}
        >
          <Typography variant="h6" marginBottom={1}>
            {item.classTitle}
          </Typography>
          <Typography variant="body2" marginBottom={2}>
            {item.description}
          </Typography>

          {/* Create list items */}
          {item.items &&
            item.items.length !== 0 &&
            item.items.map((listItem, index) => (
              <Typography
                component={"li"}
                key={listItem + index}
                variant="body2"
                sx={{ paddingBottom: 1 }}
              >
                {listItem}
              </Typography>
            ))}
        </Box>
      ))}
    </Box>
  );
};

export default ClassExplanations;
