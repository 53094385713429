import React, { useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import debounce from "lodash/debounce";
import { Hotspot } from "../shared-types";

interface LazyLoadBackgroundProps {
  alt?: string;
  imgURL?: string;
  hotspot?: Hotspot;
  children?: React.ReactNode;
  sx?: object;
}

const LazyLoadBackground: React.FC<LazyLoadBackgroundProps> = ({
  alt = "Image",
  imgURL,
  hotspot,
  children,
  sx,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  // Debounced observer callback to improve performance
  const handleIntersect = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver,
  ) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.unobserve(entry.target); // Stop observing after the image is loaded
      }
    });
  };

  const debouncedHandleIntersect = debounce(handleIntersect, 200);

  useEffect(() => {
    const current = boxRef.current;

    const observer = new IntersectionObserver(debouncedHandleIntersect, {
      // Using rootMargin to trigger image loading before it actually comes into view.
      // Loads image when it is 300px from the viewport's bottom
      rootMargin: "300px",
    });

    if (current) observer.observe(current);

    return () => {
      if (current) observer.unobserve(current);
      debouncedHandleIntersect.cancel(); // Cancel debounced function on cleanup
    };
  }, [debouncedHandleIntersect]);

  return (
    <Box
      ref={boxRef}
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        opacity: isVisible ? 1 : 0,
        transition: "opacity 0.5s cubic-bezier(0.715, 0.250, 0.015, 0.815)",
        backgroundColor: theme.palette.beige.background,
        ...sx,
      }}
    >
      {imgURL && (
        <img
          src={imgURL}
          alt={alt}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: hotspot
              ? `${hotspot.x * 100}% ${hotspot.y * 100}%`
              : "center", // Set hotspot position
            opacity: isVisible ? 1 : 0,
            transition: "opacity 0.5s cubic-bezier(0.715, 0.250, 0.015, 0.815)",
          }}
        />
      )}
      {children}
    </Box>
  );
};

export default LazyLoadBackground;
