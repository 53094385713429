import i18n from "i18next";

// Navigation items to use in Header and MobileDrawer
export const navItems = [
  { path: "paths.home", title: "pageTitles.home", desktop: true },
  { path: "paths.services", title: "pageTitles.services", desktop: true },
  { path: "paths.schedule", title: "pageTitles.schedule", desktop: true },
  { path: "paths.pricing", title: "pageTitles.pricing", desktop: true },
  { path: "paths.about", title: "pageTitles.about", desktop: true },
  { path: "paths.whyMe", title: "pageTitles.whyMe", desktop: false },
  { path: "paths.faq", title: "pageTitles.faq", desktop: true },
  {
    path: "paths.testimonials",
    title: "pageTitles.testimonials",
    desktop: false,
  },
  {
    path: "paths.instagramFeed",
    title: "pageTitles.instagramFeed",
    desktop: false,
  },
  { path: "paths.contact", title: "pageTitles.contact", desktop: true },
];

export const getAlternateLang = () => (i18n.language === "en" ? "fi" : "en");
