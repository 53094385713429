import { Box, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import ServicesList from "../components/Services/ServicesList";
import { brandLinesSxProps } from "../constants";

// green-downward-wave-.svg
// Use SVG background as component to adjust strokewidth dynamically based on breakpoints
const GreenDownwardWave: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  const theme = useTheme();

  // Define breakpoints using MUI's theme.breakpoints
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  // Adjust strokeWidth based on breakpoints
  let strokeWidth;
  switch (true) {
    case isLg:
      strokeWidth = 8;
      break;
    case isMd:
      strokeWidth = 4;
      break;
    default:
      strokeWidth = 2; // fallback for any undefined cases
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 540 960" {...props}>
      <path
        fill="none"
        stroke={theme.palette.beige.background}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        d="m111 0 35 15c30 14 0 43 90 72s-90 59-152 88c-63 29-98 58-38 87s215 58 236 87c20 29-94 58-167 87S82 495 61 524c-22 29-33 58-27 87 5 29 27 58 72 87s113 58 175 87c61 29 117 59 154 88s57 58 66 73l10 14"
      />
    </svg>
  );
};

const Services: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      className="pageWrapper"
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        background: `linear-gradient(160deg, ${theme.palette.beige.main} 19%, ${theme.palette.beige.light} 38%, ${theme.palette.beige.light} 52%, ${theme.palette.beige.main} 84%)`,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <GreenDownwardWave
        style={{
          position: "absolute",
          top: 0,
          left: "50%",
          height: "100%",
          transform: "translateX(-50%)",
        }}
      />
      {/* Brandlines graphic */}
      <Box
        sx={{
          ...brandLinesSxProps,
          position: "absolute",
          top: 20,
          left: 20,
        }}
      />
      <Box
        sx={{
          ...brandLinesSxProps,
          position: "absolute",
          bottom: 20,
          right: 20,
        }}
      />
      <ServicesList />
    </Box>
  );
};

export default Services;
