import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";

interface HistoryStateChangeEventDetail {
  visibleUrl: string;
}

// Custom hook to manage history state
export const useHistoryState = () => {
  const [visibleUrl, setVisibleUrl] = useState(
    window.history.state?.visibleUrl || "",
  );

  useEffect(() => {
    // Listen for the custom event we will dispatch
    const historyStateChangeHandler = (event: Event) => {
      if (event instanceof CustomEvent) {
        setVisibleUrl(event.detail.visibleUrl);
      }
    };

    // Add event listeners
    window.addEventListener(
      "historyStateChange",
      historyStateChangeHandler as EventListener,
    );

    return () => {
      // Cleanup listeners on unmount
      window.removeEventListener(
        "historyStateChange",
        historyStateChangeHandler as EventListener,
      );
    };
  }, []);

  return visibleUrl;
};

const ScrollTracker: React.FC = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    // Create a debounced scroll handler
    const handleScroll = debounce(() => {
      const viewportHeight = window.innerHeight;

      const sections = Object.keys(
        i18n.getResource(i18n.language, "translation", "paths") as Record<
          string,
          string
        >,
      );

      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          const isVisible =
            rect.top <= viewportHeight / 2 && rect.bottom >= viewportHeight / 2;

          if (isVisible) {
            const newPath = t(`paths.${section}`);
            // Set custom state to check it elsewhere when needed
            window.history.replaceState({ visibleUrl: newPath }, "", newPath); // Change the URL without navigation

            // Dispatch a custom event to notify state change
            const stateChangeEvent =
              new CustomEvent<HistoryStateChangeEventDetail>(
                "historyStateChange",
                {
                  detail: { visibleUrl: newPath },
                },
              );
            window.dispatchEvent(stateChangeEvent);
          }
        }
      });
    }, 250);

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Cleanup function to remove event listener and cancel debounce
      window.removeEventListener("scroll", handleScroll);
      handleScroll.cancel(); // Cancel any pending debounced calls on cleanup
    };
  }, [i18n, location.pathname, t]); // Dependencies for useEffect

  return null; // This component does not render anything to the DOM
};

export default ScrollTracker;
