import { createTheme } from "@mui/material/styles";

// Create a theme with a custom palette
const theme = createTheme({
  palette: {
    primary: {
      main: "#4c665e",
      contrastText: "#e2e9e7",
      dark: "#344641",
    },
    secondary: {
      main: "#aacec3",
    },
    // Adding custom colors for beige
    beige: {
      main: "#F6E6C3",
      light: "#FFEECA",
      background: "#C3A689",
    },
    orange: {
      main: "#FF8B5D",
    },
    overlay: {
      dark: "#344641bb", // primary.dark with 0.6 alpha
      darkForScroll: "#344641cc", // primary.dark with more alpha
    },
    white: {
      regular: "#f9fbfa",
    },
  },
  typography: {
    // Set default color for all typography variants
    allVariants: {
      color: "#344641", // primary.dark
    },
    h1: {
      color: "#4c665e", // primary.main
      fontSize: "4rem",
      [`@media (max-width: 900px)`]: {
        fontSize: "3rem",
      },
      [`@media (max-width: 600px)`]: {
        fontSize: "2.25rem",
      },
    },
    h2: {
      color: "#4c665e", // primary.main
      fontSize: "4rem",
      [`@media (max-width: 900px)`]: {
        fontSize: "3rem",
      },
      [`@media (max-width: 600px)`]: {
        fontSize: "2rem",
      },
    },
    h4: {
      color: "#4c665e", // primary.main
      fontSize: "3rem",
      [`@media (max-width: 900px)`]: {
        fontSize: "2.25rem",
      },
    },
    h5: {
      fontSize: "1.5rem",
      [`@media (max-width: 900px)`]: {
        fontSize: "1.125rem",
      },
    },
    h6: {
      fontSize: "1.25rem",
      [`@media (max-width: 900px)`]: {
        fontSize: "1rem",
      },
    },
    body1: {
      color: "#344641", // primary.dark
      fontSize: "1rem",
      [`@media (max-width: 600px)`]: {
        fontSize: "0.75rem",
      },
    },
    body2: {
      color: "#344641",
      fontSize: "0.875rem",
      [`@media (max-width: 600px)`]: {
        fontSize: "0.75rem",
      },
    },
    fontFamily: "Open Sans, Arial, sans-serif",
  },
});

export default theme;
