import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HeaderImage from "../components/HeaderImage";
import images from "../utils/images";
import { fetchHomeContent } from "../utils/sanityUtils";
import { HomeContent } from "../shared-types";
import { imageNotFoundFallback } from "../constants";

const Home: React.FC = () => {
  const { t, i18n } = useTranslation(); // Get the t function for translations
  const theme = useTheme();
  const [data, setData] = useState<HomeContent>({
    pageTitle: t("pageTitle"),
    pageSubtitle: t("pageSubtitle"),
    welcomeTitle: t("fetchingData"),
    welcomeBodyText: t("fetchingData"),
    landingImage: {
      imageUrl: imageNotFoundFallback,
      alt: "Something didn't work somewhere",
    },
  });

  // Fetch data when language changes
  useEffect(() => {
    fetchHomeContent(i18n.language).then((dataFromSanity: HomeContent) => {
      setData(dataFromSanity);
    });
  }, [i18n.language]);

  return (
    <>
      <HeaderImage
        imgUrl={data.landingImage.imageUrl}
        alt={data.landingImage.alt}
        pageTitle={data.pageTitle}
        pageSubtitle={data.pageSubtitle}
        hotspot={data.landingImage.hotspot}
        height={800}
        blackAndWhite
        mainHeading
      />
      <Box
        className="pageWrapper"
        sx={{
          display: "flex",
          position: "relative",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          overflow: "hidden",
          backgroundImage: `url(${images.blobScene})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: { xs: 200, sm: 400 },
          backgroundColor: theme.palette.beige.light,
          padding: "64px 32px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontFamily: "Dahlia, Arial, sans-serif",
            boxSizing: "border-box",
            paddingBottom: { xs: 4, sm: 8 },
          }}
        >
          {data?.welcomeTitle}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            zIndex: 1,
            whiteSpace: "pre-line",
            maxWidth: { xs: 200, sm: 400 },
            textAlign: "center",
          }}
        >
          {data?.welcomeBodyText}
        </Typography>
      </Box>
    </>
  );
};

export default Home;
