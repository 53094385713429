import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Link,
  Stack,
  useTheme,
} from "@mui/material";
import CardIcon from "@mui/icons-material/CreditCard";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  phoneNumber,
  emailAddress,
  brandLinesSxProps,
  filterBeigeLight,
} from "../../constants";
import LazyLoadMap from "./LazyLoadGoogleMaps";
import images from "../../utils/images";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: 3,
        paddingBottom: 2,
        textAlign: "center",
        backgroundImage: `url(${images.threeSimpleWaves})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      {/* Social Media Icons with @wellinyoga */}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ marginBottom: 4 }}
      >
        <IconButton
          component="a"
          aria-label={t("ariaLabels.seeMyInstagram")}
          href="https://www.instagram.com/wellinyoga"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: theme.palette.orange.main,
            display: "flex",
            alignItems: "center",
            padding: "8px 16px",
            borderRadius: "8px",
          }}
        >
          <InstagramIcon />
          <Typography
            variant="body2"
            sx={{ marginLeft: 1, color: theme.palette.orange.main }}
          >
            @wellinyoga
          </Typography>
        </IconButton>

        <IconButton
          component="a"
          aria-label={t("ariaLabels.seeMyFacebook")}
          href="https://www.facebook.com/denniswellingtoneyoga/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: theme.palette.orange.main,
            display: "flex",
            alignItems: "center",
            padding: "8px 16px",
            borderRadius: "8px",
          }}
        >
          <FacebookIcon />
          <Typography
            variant="body2"
            sx={{ marginLeft: 1, color: theme.palette.orange.main }}
          >
            @wellinyoga
          </Typography>
        </IconButton>
      </Stack>

      {/* Logo */}
      <Box sx={{ marginBottom: 4 }}>
        <img
          src={images.logo}
          alt={`${t("siteTitle")} logo`}
          aria-label={`${t("siteTitle")} logo`}
          style={{
            height: 128,
            boxShadow: `0px 2px 4px ${theme.palette.beige.background}`,
          }}
        />
      </Box>

      {/* Address */}
      <Typography
        component={"address"}
        aria-label={t("ariaLabels.wellinyogaStreetAddress")}
        variant="body2"
        sx={{
          marginBottom: 4,
          color: theme.palette.primary.dark,
          fontStyle: "normal",
        }}
      >
        Wellinyoga
        <br />
        Pirkankatu 11
        <br />
        33230, Tampere
      </Typography>

      {/* Google Maps Embed */}
      <LazyLoadMap />

      {/* Payment options */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 8,
          marginBottom: 6,
          padding: 2,
          paddingLeft: 4,
          paddingRight: 4,
          backgroundColor: theme.palette.beige.main,
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "fit-content",
          width: "90%",
          borderRadius: "8px",
          boxShadow: `0px 4px 8px ${theme.palette.overlay.dark}`,
        }}
        aria-label={t("ariaLabels.paymentOptions")}
      >
        {/* Title */}
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "1.125rem", md: "1.5rem" },
            color: theme.palette.primary.main,
            fontFamily: "Dahlia, Arial, sans-serif",
          }}
        >
          {t("paymentOptions")}
        </Typography>

        {/* Payment Icons */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: { xs: 2, sm: 4 },
            padding: 4,
            paddingLeft: 0,
            paddingRight: 0,
            alignItems: "baseline",
          }}
        >
          <CardIcon
            sx={{
              height: { xs: 24, sm: 32 },
              width: { xs: 24, sm: 32 },
              color: theme.palette.primary.dark,
            }}
            aria-label={t("ariaLabels.paymentCard")}
          />
          <Box
            component={"img"}
            src={images.velloLogo}
            alt={t("ariaLabels.paymentVello")}
            sx={{ height: { xs: 24, sm: 32 } }}
            aria-label={t("ariaLabels.paymentVello")}
          />
          <Box
            component={"img"}
            src={images.edenredLogo}
            alt={t("ariaLabels.paymentEdenred")}
            sx={{ height: { xs: 24, sm: 32 } }}
            aria-label={t("ariaLabels.paymentEdenred")}
          />
          <Box
            component={"img"}
            src={images.smartumPayLogo}
            alt={t("ariaLabels.paymentSmartumPay")}
            sx={{ height: { xs: 24, sm: 32 } }}
            aria-label={t("ariaLabels.paymentSmartumPay")}
          />
          <Box
            component={"img"}
            src={images.epassiLogo}
            alt={t("ariaLabels.paymentEpassi")}
            sx={{ height: { xs: 24, sm: 32 } }}
            aria-label={t("ariaLabels.paymentEpassi")}
          />
        </Box>
      </Box>

      {/* Call to action, phone and email */}
      <Stack
        component={"address"}
        direction="row"
        alignItems="center"
        flexWrap={"wrap"}
        spacing={2}
        gap={2}
        maxWidth={400}
        margin={"0 auto"}
        sx={{
          justifyContent: { xs: "center", sm: "space-between" },
          fontStyle: "normal",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            paddingBottom: 2,
            color: theme.palette.primary.contrastText,
            width: "100%",
            fontStyle: "italic",
          }}
        >
          {t("footerCTA")}
        </Typography>
        <Link
          aria-label={t("ariaLabels.contactMeViaPhone")}
          href={`tel:${phoneNumber}`}
          sx={{
            color: theme.palette.primary.contrastText,
            display: "flex",
            alignItems: "center",
            fontFamily: "Open Sans, Arial, sans-serif",
            fontSize: { xs: "0.75rem", sm: "0.875rem" },
            marginLeft: "0 !important",
            marginRight: "16px !important",
            textDecoration: "none",
          }}
        >
          <PhoneIcon sx={{ marginRight: 1 }} />+{phoneNumber}
        </Link>

        <Link
          aria-label={`${t("ariaLabels.openEmailAppTo")} ${emailAddress}`}
          href={`mailto:${emailAddress}`}
          sx={{
            color: theme.palette.primary.contrastText,
            display: "flex",
            alignItems: "center",
            fontFamily: "Open Sans, Arial, sans-serif",
            fontSize: { xs: "0.75rem", sm: "0.875rem" },
            marginLeft: "0 !important",
            textDecoration: "none",
          }}
        >
          <MailIcon sx={{ marginRight: 1 }} />
          {emailAddress}
        </Link>
      </Stack>

      {/* Brandlines graphic */}
      <Box
        sx={{
          ...brandLinesSxProps,
          position: "absolute",
          bottom: 94,
          left: "50%",
          transform: "translateX(-50%)",
          filter: filterBeigeLight,
        }}
      />

      {/* Made by */}
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 18 }}>
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            alignItems: "center",
            color: theme.palette.beige.light,
          }}
        >
          {t("siteBy")}
          <Link
            href={"https://www.upwork.com/freelancers/~010a7c17e9581bb594"}
            sx={{
              color: theme.palette.beige.light,
              display: "flex",
              alignItems: "center",
              padding: 2,
              paddingLeft: 0.75,
              textDecoration: "underline",
            }}
          >
            SuorantaCoding
            <OpenInNewIcon sx={{ fontSize: "14px", marginLeft: 0.5 }} />
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
