import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InfoIcon from "@mui/icons-material/InfoRounded";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { navItems } from "../../utils/utils";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import images from "../../utils/images";
import { brandLinesSxProps, phoneNumber } from "../../constants";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

// Register ScrollToPlugin with GSAP
gsap.registerPlugin(ScrollToPlugin);

interface MobileDrawerProps {
  activePath: string;
}

const MobileDrawer: React.FC<MobileDrawerProps> = ({ activePath }) => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const isTinyMobile = useMediaQuery("(max-width: 400px)");
  const drawerWidth = 332;

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = (): void => {
    setMobileOpen(false);
  };

  const handleScroll = (path: string) => {
    const sectionName = t(path);
    const section = document.getElementsByName(sectionName)[0];
    if (section) {
      gsap.to(window, {
        duration: 0.75,
        scrollTo: { y: section, offsetY: 40 },
        ease: "power2.inOut",
      });
    }
  };

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label={`${t("ariaLabels.goTo")} ${t("ariaLabels.mainNavigation")}`}
        onClick={handleDrawerToggle}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          width: drawerWidth,
          position: "relative",
        }}
        PaperProps={{
          style: {
            borderBottomLeftRadius: "8px",
            borderTopLeftRadius: "8px",
            backgroundImage: `url(${images.beigeMainBeigeDarkGradientVertical})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          },
        }}
      >
        <Box
          component="nav"
          aria-label={t("ariaLabels.mainNavigationMobile")}
          sx={{
            width: isTinyMobile ? 250 : drawerWidth,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            height: "100%",
            boxSizing: "border-box",
          }}
        >
          {/* Site title and social media icons fixed on top*/}
          <Box
            component={"nav"}
            aria-label={t("ariaLabels.homePageWhatsappAndSocialMediaLinks")}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              padding: 2,
              paddingBottom: 2,
              flexShrink: 0,
              boxSizing: "border-box",
              boxShadow: `0px 4px 12px -14px ${theme.palette.primary.main}`, // Just at the bottom edge
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Button
                component={NavLink}
                onClick={() => {
                  handleScroll(t("paths.home")); // Handle scrolling
                  handleDrawerClose(); // Close the drawer
                }}
                aria-label={t("ariaLabels.toHome")}
                to={t("paths.home")}
                sx={{
                  textDecoration: "none",
                  padding: 0,
                }}
              >
                <img
                  src={images.logo}
                  alt={`${t("siteTitle")} ${t("pageTitles.home")}`}
                  style={{ height: 64 }}
                />
              </Button>
            </Box>

            <IconButton
              component="a"
              aria-label={t("ariaLabels.sendMeWhatsApp")}
              href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=`}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: theme.palette.orange.main,
                padding: "8px 16px",
                borderRadius: "8px",
              }}
            >
              <WhatsAppIcon />
            </IconButton>

            <IconButton
              component="a"
              aria-label={t("ariaLabels.seeMyInstagram")}
              href="https://www.instagram.com/wellinyoga"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: theme.palette.orange.main,
                padding: "8px 16px",
                borderRadius: "8px",
              }}
            >
              <InstagramIcon />
            </IconButton>

            <IconButton
              component="a"
              aria-label={t("ariaLabels.seeMyFacebook")}
              href="https://www.facebook.com/denniswellingtoneyoga/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: theme.palette.orange.main,
                padding: "8px 16px",
                borderRadius: "8px",
              }}
            >
              <FacebookIcon />
            </IconButton>
          </Box>

          {/* Scrollable section */}
          <Box
            component={"nav"}
            aria-label={t("ariaLabels.mainNavigationMobileLinks")}
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              paddingTop: 2,
              paddingBottom: 2,
              paddingLeft: 1,
              // Custom scrollbar styling
              "&::-webkit-scrollbar": {
                height: "5px",
                width: "5px",
              },
              "&::-webkit-scrollbar-track": {
                borderRadius: "8px",
                backgroundColor: theme.palette.beige.main,
              },
              "&::-webkit-scrollbar-track:hover": {
                backgroundColor: theme.palette.beige.main,
              },
              "&::-webkit-scrollbar-track:active": {
                backgroundColor: theme.palette.beige.main,
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "5px",
                backgroundColor: theme.palette.beige.background,
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: theme.palette.beige.background,
              },
              "&::-webkit-scrollbar-thumb:active": {
                backgroundColor: theme.palette.beige.background,
              },
            }}
          >
            {navItems.map((item) => (
              <Button
                onClick={() => {
                  handleScroll(item.path); // Handle scrolling
                  handleDrawerClose(); // Close the drawer
                }}
                aria-label={`${t("ariaLabels.goTo")} ${t(item.title)}`}
                variant="text"
                component={NavLink}
                to={t(item.path)} // Update the URL
                key={item.path}
                sx={{
                  color:
                    item.path === activePath
                      ? theme.palette.primary.main
                      : theme.palette.primary.dark,
                  width: "100%",
                  justifyContent: "flex-start",
                  textDecoration: "none",
                  marginBottom: 1,
                }}
              >
                <Box
                  sx={{
                    paddingRight: 2,
                    borderBottom:
                      item.path === activePath
                        ? `2px solid ${theme.palette.primary.main}`
                        : "none",
                  }}
                >
                  {t(item.title)}
                </Box>
              </Button>
            ))}
            {/* Brandlines graphic */}
            <Box
              sx={{
                ...brandLinesSxProps,
                width: "50%",
                margin: "32px auto",
                height: 50,
              }}
            />
          </Box>

          {/* Info Icon and text */}
          <Box
            aria-label={t("openByScheduleAndByAppointment")}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: 2,
              boxSizing: "border-box",
              boxShadow: `0px -4px 14px -14px ${theme.palette.primary.main}`, // Just at the top edge
            }}
          >
            <InfoIcon
              sx={{ color: theme.palette.orange.main, marginRight: 1 }}
            />
            <Typography
              variant="body2"
              sx={{ color: theme.palette.primary.dark }}
            >
              {t("openByScheduleAndByAppointment")}
            </Typography>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default MobileDrawer;
