import React, { useEffect, useRef, useState } from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const LazyLoadMap: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const current = ref.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.1 }, // Adjust threshold as needed
    );

    if (current) {
      observer.observe(current);
    }

    if (isVisible) {
      observer.disconnect(); // Disconnect after visibility is set
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [isVisible]);

  return (
    <Box
      width={{ xs: "100%", sm: "500px" }}
      sx={{
        margin: "0 auto",
        marginBottom: 4,
      }}
      ref={ref}
    >
      {isVisible ? (
        <iframe
          title={t("inGoogleMaps")}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1903.8879815741634!2d23.737083199999997!3d61.49818260000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468ed96efe8086db%3A0x9e7e097eb90a250f!2sWellinyoga!5e0!3m2!1sen!2sfi!4v1729787799779!5m2!1sen!2sfi"
          width="100%"
          height="300"
          style={{
            border: "0",
            borderRadius: "8px",
            boxShadow: `0 2px 5px ${theme.palette.overlay.dark}`,
          }}
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          loading="lazy"
        />
      ) : (
        <Box
          sx={{
            height: "300px",
            borderRadius: "8px",
            backgroundColor: theme.palette.primary.main,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="body2"
            mb={2}
            sx={{ color: theme.palette.primary.contrastText }}
          >
            {t("inGoogleMaps")}
          </Typography>
          <CircularProgress sx={{ color: theme.palette.secondary.main }} />
        </Box>
      )}
    </Box>
  );
};

export default LazyLoadMap;
