import React, { useEffect, useState } from "react";
import ScheduleTable from "../components/Schedule/ScheduleTable";
import ClassExplanations from "../components/Schedule/ClassExplanations";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import HeaderImage from "../components/HeaderImage";
import images from "../utils/images";
import { SanityImage } from "../shared-types";
import { fetchScheduleImage } from "../utils/sanityUtils";
import { brandLinesSxProps, imageNotFoundFallback } from "../constants";

const Schedule: React.FC = () => {
  const { t } = useTranslation();
  const [imageData, setImageData] = useState<SanityImage>({
    imageUrl: imageNotFoundFallback,
    alt: "Something didn't work somewhere",
  });

  // Fetch image
  useEffect(() => {
    fetchScheduleImage().then((dataFromSanity: SanityImage) => {
      if (dataFromSanity.imageUrl) {
        setImageData(dataFromSanity);
      }
    });
  }, []);

  return (
    <>
      <HeaderImage
        alt={imageData.alt}
        imgUrl={imageData.imageUrl}
        hotspot={imageData.hotspot}
        pageTitle={t("pageTitles.schedule")}
        height={400}
        blackAndWhite
      />
      <Box
        className="pageWrapper"
        sx={{
          backgroundImage: `url(${images.verticalStackedWaves})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {/* Brandlines graphic */}
        <Box
          sx={{
            ...brandLinesSxProps,
            position: "absolute",
            top: 20,
            right: 20,
          }}
        />
        <Box
          sx={{
            ...brandLinesSxProps,
            position: "absolute",
            bottom: 20,
            left: 20,
          }}
        />
        <ScheduleTable />
        <ClassExplanations />
      </Box>
    </>
  );
};

export default Schedule;
