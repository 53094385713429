import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import debounce from "lodash/debounce";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

// Register ScrollToPlugin with GSAP
gsap.registerPlugin(ScrollToPlugin);

interface CollapsibleProps {
  collapsedHeight?: number; // Default collapsed height
  children: React.ReactNode; // Content to display inside collapsible
  buttonLabels?: { readMore: string; showLess: string }; // Optional button text customization
  parentRef?: React.RefObject<HTMLElement>; // For scrolling to parent's position when "Show less" is clicked
}

const Collapsible: React.FC<CollapsibleProps> = ({
  collapsedHeight = 140, // Default to 140px if not provided
  children,
  buttonLabels = { readMore: "Show more", showLess: "Show less" },
  parentRef,
}) => {
  const theme = useTheme();
  const [collapsed, setCollapsed] = useState(true);
  const [contentHeight, setContentHeight] = useState(0);
  const collapsibleRef = useRef<HTMLDivElement>(null);

  const toggleCollapse = () => {
    if (collapsed) {
      setCollapsed(!collapsed);
    } else {
      scrollToParent();
      setTimeout(() => {
        setCollapsed(!collapsed);
      }, 900);
    }
  };

  // Debounced version of calculateContentHeight to limit the frequency of updates
  const debouncedCalculateContentHeight = debounce(() => {
    if (collapsibleRef.current) {
      setContentHeight(collapsibleRef.current.scrollHeight);
    }
  }, 1000);

  const scrollToParent = () => {
    if (parentRef?.current) {
      gsap.to(window, {
        duration: 0.45,
        scrollTo: { y: parentRef.current },
        ease: "power2.out",
      });
    }
  };

  useEffect(() => {
    // Call the debounced function initially to set content height
    debouncedCalculateContentHeight();

    const current = collapsibleRef.current;

    const observer = new ResizeObserver(() => {
      debouncedCalculateContentHeight();
    });

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
      debouncedCalculateContentHeight.cancel(); // Clean up debounced function on unmount
    };
  }, [debouncedCalculateContentHeight]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {/* Collapsible Content */}
      <Box
        ref={collapsibleRef}
        sx={{
          overflow: "hidden",
          maxHeight: collapsed ? collapsedHeight : `${contentHeight}px`,
          transition: "max-height 0.5s ease-in-out",
        }}
      >
        {children}
        {/* Small element for holding space for box-shadows etc. */}
        <Box sx={{ pointerEvents: "none", height: "10px", width: "10px" }} />
      </Box>

      {/* Ellipsis for collapsed state */}
      <Typography
        variant="body2"
        sx={{
          transition: "opacity 0.5s ease-in-out",
          opacity: collapsed ? 1 : 0,
          mt: 1,
          fontSize: "24px",
        }}
      >
        ...
      </Typography>

      {/* Toggle Button */}
      <Button
        aria-label={collapsed ? buttonLabels.readMore : buttonLabels.showLess}
        onClick={toggleCollapse}
        sx={{
          mt: 4,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          width: 200,
        }}
        variant="contained"
      >
        {collapsed ? buttonLabels.readMore : buttonLabels.showLess}
        <ExpandMoreIcon
          sx={{
            transform: collapsed ? "rotate(0deg)" : "rotate(180deg)",
            transition: "transform 0.3s ease-in-out",
            color: theme.palette.primary.contrastText,
            ml: "auto",
          }}
        />
      </Button>
    </Box>
  );
};

export default Collapsible;
